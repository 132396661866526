<template>
  <div class="_section-body" ref="feedback_head">
    <div v-if="feedbackSlotLoading" class="loading">Loading...</div>
    <div v-else-if="feedback">
      <ul class="list-unstyled discussion-list">
        <li v-for="slot in feedback" :key="slot.id">
          <div class="discussion-card">
            <div class="_card-head">
              <div class="thumbnail" v-if="$route.name === 'MyFeedback'">
                <img
                  v-if="slot.author.image != null"
                  :src="slot.author.image"
                  alt=""
                />
                <img v-else src="../assets/thumb.png" alt="..." />
              </div>
              <h6 v-if="$route.name === 'MyFeedback'">
                {{ slot.author.author_name }}
              </h6>
              <p v-if="$route.name === 'MyFeedback'">
                {{ convertedTimeForUser(slot.created_at) }}
              </p>
              <!-- For editing the discussion threads -->
              <a
                v-if="slot.status == 'DRAFT'"
                @click.prevent="$emit('editFeedback', slot.id)"
              >
                <img src="../assets/pen-solid.svg" alt="" />
              </a>
            </div>
            <div class="_card-body">
              <p class="main" v-if="toggleTitle">
                {{ slot.feedback }}
              </p>
              <div class="subcomments">
                <ul class="list-unstyled">
                  <li>
                    <p v-if="!toggleTitle">
                      {{ slot.feedback_reply }}
                    </p>
                    <p v-if="toggleTitle">
                      Category : {{ slot.category_title }}
                    </p>
                    <p v-if="toggleTitle">Rating : {{ slot.rating }}</p>
                    <p v-if="toggleTitle">Status : {{ slot.status }}</p>
                    <div
                      class="info"
                      v-if="togglePostCount && slot.status != 'DRAFT'"
                    >
                      <router-link
                        :to="{
                          name: 'FeedbackView',
                          params: {
                            feedback_id: slot.id,
                          },
                        }"
                      >
                        <span>
                          <img
                            src="../assets/comment-icon.svg"
                            alt=""
                            title="View Replies"
                          />
                        </span>
                      </router-link>
                    </div>
                    <a
                      class="btn small-cta"
                      href="#"
                      v-if="slot.status == 'DRAFT'"
                      ref="publishContainer"
                      @click.prevent="publishFeedback(slot.id)"
                    >
                      Publish
                    </a>
                    <div class="info" v-if="togglePostCount == false"></div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="paging-wrapper py-3">
        <v-pagination
          v-model="feedbackPage"
          :pages="totalFeedbackPages"
          :range-size="1"
          active-color="#DCEDFF"
          @update:modelValue="feedbackPaginateUpdateHandler"
          v-if="totalFeedback > 0"
        />
      </div>
      <EmptyList v-if="totalFeedback == 0" :messageText="emptyMessage" />
    </div>
  </div>
</template>
<script>
import FeedbackService from "@/services/FeedbackService";
import EmptyList from "@/components/EmptyList.vue";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Timezone from "@/services/Timezone";

export default {
  name: "FeedbackSlot",
  components: {
    EmptyList,
    VPagination,
  },
  props: {
    togglePostCount: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      feedbackSlotLoading: true,
      toggleTitle: true,
      emptyMessage: "",
      // Initial page number for feedback if any
      feedbackPage: 1,
      currentFeedbackPage: 1,
      totalFeedbackPages: 0,
      totalFeedback: 0,
      feedback: [],
      subscribedCount: 0,
      fullPage: true,
    };
  },
  created() {
    // console.log(this.$route.name);
    this.switchRoute();
  },
  methods: {
    switchRoute() {
      let params = {};
      let routeName = this.$route.name;
      let feedback_id =
        this.$route.params.feedback_id != undefined
          ? this.$route.params.feedback_id
          : "";
      if (routeName === "MyFeedback") {
        params = {
          page: this.currentFeedbackPage,
        };
        this.emptyMessage = "You haven't added any feedback yet!";
        this.fetchAllFeedback(params);
      } else if (routeName === "FeedbackView") {
        this.toggleTitle = false;
        params = {
          page: this.currentFeedbackPage,
          feedback_id: feedback_id,
        };
        this.emptyMessage = "No replies found!";
        this.fetchFeedbackReplies(params);
      }
    },
    convertedTimeForUser(date) {
      let convertedDay = Timezone.convertedTimeZone(date).fromNow();
      return convertedDay;
    },
    async fetchAllFeedback(params) {
      await FeedbackService.getAllFeedback(params)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            this.feedbackSlotLoading = false;
            this.feedback = response.data.feedback.data;
            // works only when totalFeedbackPages should be the last_page value
            this.totalFeedbackPages = response.data.feedback.last_page;
            this.currentFeedbackPage = response.data.feedback.current_page;
            this.totalFeedback = response.data.feedback.total;
            this.subscribedCount = response.data.subscribed_count;
            if (this.subscribedCount == 0) {
              this.$emit("noSubscription", false);
              this.emptyMessage = "Please subscribe any program first!";
            } else {
              this.$emit("noSubscription", true);
            }
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    feedbackPaginateUpdateHandler(selectedPage) {
      if (this.currentFeedbackPage !== selectedPage) {
        this.currentFeedbackPage = selectedPage;
        this.scrollToElement(this.$refs.feedback_head);
        this.switchRoute();
      }
    },

    async fetchFeedbackReplies(params) {
      await FeedbackService.fetchFeedbackReplies(params)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            this.feedbackSlotLoading = false;
            this.feedback = response.data.feedback_replies.data;
            this.totalFeedbackPages = response.data.feedback_replies.last_page;
            this.currentFeedbackPage =
              response.data.feedback_replies.current_page;
            this.totalFeedback = response.data.feedback_replies.total;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            let errorsObject = response.data.errors;
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    publishFeedback: function (feedbackId) {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.publishContainer,
      });

      let params = {
        feedback_id: feedbackId,
        status: "PENDING",
      };
      FeedbackService.publishFeedback(params)
        .then((response) => {
          loader.hide();
          if (response.data.status == "SUCCESS") {
            this.$toast.success(response.data.message);
            this.fetchAllFeedback();
          } else if (response.data.status == "ERROR") {
            this.$toast.error(response.data.message);
          } else if (response.data.status === "VALIDATION_FAILED") {
            let errorsObject = response.data.errors;
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    scrollToElement(refId) {
      const el = refId;
      if (el) {
        el.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/style/discussion-board.scss";
</style>
